<template>
  <div>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <!-- <el-button type="primary" 
                      @click="addrecordVisible = true"
                      >预约车辆</el-button> -->
          <el-button type="primary" @click="sendPoints">积分派送</el-button>
        </el-col>
        <!-- <el-col :span="4">
          <el-button type="primary" @click="addBirthday">添加生日</el-button>
        </el-col> -->
      </el-row>
    </el-card>
    <!-- <el-form
      :gutter="20"
      ref="passwordForm"
      :model="passwordData"
      label-width="120px"
      style="width: 40%; margin-top: 50px"
    >
      <el-form-item label="原密码" prop="oldPassword">
        <el-input type="password" v-model="passwordData.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="passwordData.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirmNewPassword">
        <el-input
          type="password"
          v-model="passwordData.confirmNewPassword"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePassword">修改密码</el-button>
      </el-form-item>
    </el-form> -->
    <!--  修改密码区域 -->
    <el-dialog
      closable
      title="积分派送"
      :visible.sync="sendPointsVisible"
      width="60%"
      destroyOnClose
    >
      <el-form
        :gutter="20"
        ref="pointsForm"
        :model="pointsData"
        label-width="120px"
        style="width: 40%; margin-top: 50px"
      >
        <el-form-item label="派送积分" prop="points">
          <el-input type="points" v-model="pointsData.points"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="sendPointsCancel">取 消</el-button>
      <el-button type="primary" @click="sendPointsOk">确 定</el-button>
    </el-dialog>

    <el-dialog
      closable
      title="修改密码"
      :visible.sync="changePasswordVisible"
      width="60%"
      destroyOnClose
    >
      <el-form
        :gutter="20"
        ref="passwordForm"
        :model="passwordData"
        label-width="120px"
        style="width: 40%; margin-top: 50px"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="passwordData.oldPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="passwordData.newPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmNewPassword">
          <el-input
            type="password"
            v-model="passwordData.confirmNewPassword"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
            <el-button type="primary" @click="changePassword">修改密码</el-button>
          </el-form-item> -->
      </el-form>
      <el-button @click="changePasswordCancel">取 消</el-button>
      <el-button type="primary" @click="changePasswordOk">确 定</el-button>
    </el-dialog>

    <el-dialog
      closable
      title="添加生日"
      :visible.sync="addBirthdayVisible"
      width="60%"
      destroyOnClose
    >
      <el-form
        :gutter="20"
        ref="birthdayForm"
        :model="userData"
        label-width="120px"
        style="width: 40%; margin-top: 50px"
      >
        <el-form-item label=" 生日" prop="birthday">
          <el-date-picker
            v-model="userData.birthday"
            type="date"
            placeholder="选择生日"
            format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button @click="addBirthdayCancel">取 消</el-button>
      <el-button type="primary" @click="addBirthdayOk">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sendPointsVisible: false,
      addBirthdayVisible: false,
      changePasswordVisible: false,
      userData: {
        birthday: undefined,
      },
      pointsData: {
        points: undefined,
      },
      passwordData: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      passwordError: "", // 用于显示密码错误信息
    };
  },
  methods: {
    changePassword() {
      this.changePasswordVisible = true;
    },
    sendPoints() {
      this.sendPointsVisible = true;
      // this.getperiodList();
    },
    async sendPointsOk() {
      const { data: res } = await this.$http.post("sendPoints", {
        points: parseInt(this.pointsData.points),
      });
      if (res.status != 200) return this.$message.error(res.message);
      this.$message.success("成功派送积分！");
      this.sendPointsVisible = false;
      // this.$router.push("/login");
    },
    sendPointsCancel() {
      this.$refs.pointsForm.resetFields();
      this.sendPointsVisible = false;
    },

    addBirthday() {
      this.addBirthdayVisible = true;
      // this.getperiodList();
    },
    addBirthdayCancel() {
      this.$refs.birthdayForm.resetFields();
      this.addBirthdayVisible = false;
    },
    async addBirthdayOk() {
      const { data: res } = await this.$http.post("addBirthday", this.userData);
      if (res.status != 200) return this.$message.error(res.message);
      this.$message.success("成功添加生日！");
      // this.$router.push("/login");
    },

    async changePasswordOk() {
      const { data: res } = await this.$http.post(
        "changePassword",
        this.passwordData
      );
      if (res.status != 200) return this.$message.error(res.message);
      this.$message.success("成功修改密码!请用新密码重新登录！");
      this.$router.push("/login");
    },
    changePasswordCancel() {
      this.$refs.passwordForm.resetFields();
      this.changePasswordVisible = false;
    },
  },
};
</script>
