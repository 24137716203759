<template>
    <div class="dashboard-container">
        <h3 class="title">{{ id ? '编辑JZZ' : '新增JZZ' }}</h3>
        <el-card>

            <el-form :model="lectureInfo" :rules="lectureInfoRules" ref="lectureInfoRef" :hideRequiredMark="true"
                label-width="100px" size="mini">
                <el-row>
                    <el-col :span="15">
                        <el-form-item label="题目" style="width: 700px; " prop="username">
                            <el-input v-model="lectureInfo.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="分类" prop="cid">
                            <el-select v-model="lectureInfo.cid" placeholder="请选择分类" style="width: 200px;"
                                @change="catChange">
                                <el-option v-for="item in Catlist" :key="item.ID" :value="item.ID" :label="item.name">
                                    {{item.name}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="15">
                        <el-form-item label="开始时间" style="width: 350px;" prop="username">
                            <el-date-picker v-model="lectureInfo.begin_time" type="datetime" placeholder="选择开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="结束时间" style="width: 350px;" prop="username">
                            <el-date-picker v-model="lectureInfo.end_time" type="datetime" placeholder="选择开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>


                <!-- <el-form-item label="讲座介绍" style="width: 350px;" prop ="username">
                    <el-input v-model="lectureInfo.content" ></el-input>
                </el-form-item> -->


                <el-row>
                    <el-col :span="3">
                        <el-form-item label="是否线上" style="width: 100px;" prop="username">
                            <el-radio-group v-model="lectureInfo.on_line">
                                <el-radio :label="true" style="margin-bottom: 10px;">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                            <!-- <el-input v-model="lectureInfo.on_line" ></el-input> -->
                        </el-form-item>

                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="线上类型" style="width: 200px;" prop="username">
                            <el-select v-model="lectureInfo.on_line_type" placeholder="请选择类型">
                                <!-- <el-option label="腾讯会议" :value="腾讯会议"></el-option>
                                <el-option label="Zoom" :value="Zoom"></el-option> -->
                                <el-option v-for="item in on_line_type" :label="item" :value="item"
                                    :key="item"></el-option>
                            </el-select>
                            <!-- <el-input v-model="lectureInfo.on_line_type" ></el-input> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="线上地址" style="width: 300px;" prop="username">
                            <el-input v-model="lectureInfo.on_line_addr"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="密码" style="width: 350px;" prop="username">
                            <el-input v-model="lectureInfo.on_line_pw"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单位" style="width: 350px;" prop="username">
                            <el-input v-model="lectureInfo.unit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="校区" style="width: 300px;" prop="username">
                            <el-input v-model="lectureInfo.campus"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="线下地址" style="width: 350px;" prop="username">
                            <el-input v-model="lectureInfo.addr"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-form-item label="主讲人" style="width: 350px;" prop="username">
                    <el-input v-model="lectureInfo.speaker"></el-input>
                </el-form-item>

                <el-form-item label="主讲人简介" style="width: 350px;" prop="username">
                    <el-input type="textarea" v-model="lectureInfo.speaker_profile"></el-input>
                </el-form-item>

                <el-form-item label="主持人" style="width: 350px;" prop="username">
                    <el-input v-model="lectureInfo.host"></el-input>
                </el-form-item>

                <el-form-item label="主持人简介" style="width: 350px;" prop="username">
                    <el-input type="textarea" v-model="lectureInfo.host_profile"></el-input>
                </el-form-item>

                <el-form-item label="海报" prop="image">
                    <el-upload name="file" :headers="headers" :action="upUrl" :on-success="upChange">
                        <el-button>
                            <i class="el-icon-upload"></i>上传
                        </el-button>
                        <template v-if="id">
                            <img :src="lectureInfo.image" style="width: 120px; height: 100px; margin-left: 15px" alt>
                        </template>
                    </el-upload>
                </el-form-item>

                <el-form-item label="内容" prop="role">
                    <!-- <el-input v-model="lectureInfo.content" ></el-input> -->
                    <template>
                        <mavon-editor ref=md :subfield="subfield" :code_style="code_style" :ishljs="true"
                            @imgAdd="uploadImg" @imgDel="delImg" v-model="lectureInfo.content"></mavon-editor>
                    </template>
                </el-form-item>

                <el-form-item>
                    <el-button type="danger" style="margin: 15px;" @click="lectureOk(lectureInfo.id)">
                        {{
                        lectureInfo.id ? '更新' : '提交'
                        }}
                    </el-button>
                    <el-button type="primary" style="margin: 15px;" @click="addCancel">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import { Url } from '../../plugin/http'
    export default {
        props: ['id'],
        data() {
            return {
                subfield: true,
                code_style: 'atom-one-dark',
                // lectureInfo:{
                //     id:0,
                //     title:'',
                //     cid:undefined,
                //     desc:'',
                //     content:'',
                //     img:'',
                // },
                on_line_type: ["腾讯会议", "Zoom","直播链接"],

                lectureInfo: {
                    id: 0,
                    name: '',
                    cid: undefined,
                    begin_time: undefined,
                    end_time: undefined,
                    on_line: false,
                    on_line_type: undefined,
                    on_line_addr: '',
                    on_line_pw: '',
                    unit: '',
                    compus: '',
                    addr: '',
                    speaker: '',
                    speaker_profile: '',
                    host: '',
                    host_profile: '',
                    content: '',
                    image: '',
                    status: '审核通过'
                },
                Catlist: [],
                upUrl: this.$http.defaults.baseURL + '/upload',
                headers: {},
                formData: {},
                object_dir: '',
                filelist: [],
                lectureInfoRules: {
                    title: [{ required: true, message: "输入jzz标题", trigger: "change" }],
                    cid: [{ required: true, message: '请选择JZZ分类', trigger: 'change' }],
                    desc: [
                        { required: true, message: '请输入JZZ描述', trigger: 'change' },
                        { max: 120, message: '描述最多可写120个字符', trigger: 'change' },
                    ],
                    // img: [{ required: true, message: '请选择JZZ缩略图', trigger: 'change' }],
                    content: [{ required: true, message: '请输入JZZ内容', trigger: 'change' }],

                }
            }
        },
        mounted() {
            console.log(this.upUrl)
            this.getCatList()
            this.headers = { Authorization: `Beare ${window.sessionStorage.getItem('token')}` }
            if (this.id) {
                this.getlectureInfo(this.id)

            }
        },
        methods: {

            delImg(pos, $file) {
                console.log($file)
            },

            // https://github.com/hinesboy/mavonEditor/blob/master/doc/cn/upload-images.md
            uploadImg(pos, $file) {
                console.log(this.upUrl)
                // 第一步.将图片上传到服务器.
                let formdata = new FormData();
                formdata.append('image', $file);
                // uploadPostImg(formdata).then(data => {
                //   // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                //   // $vm.$img2Url 详情见本页末尾
                //   console.log(JSON.stringify(data));
                //   this.$refs.md.$img2Url(pos, data.data);
                // });
            },

            async getlectureInfo(id) {
                const { data: res } = await this.$http.post("getLecture", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.lectureInfo = res.data
                this.lectureInfo.id = res.data.ID
            },

            async getCatList() {
                const { data: res } = await this.$http.post("getCategoryAll")
                if (res.status != 200) return this.$message.error(res.message);
                this.Catlist = res.data
            },

            catChange(cid) {
                this.lectureInfo.cid = cid
            },

            upChange(info) {
                // console.log(info)
                // if(info.status !=200){

                // }
                if (info.status == 200) {
                    this.$message.success("上传成功！")
                    this.lectureInfo.image = info.data
                } else if (info.file.status == "error") {
                    this.$message.success("上传失败！")
                }
            },

            async submitUpload(info) {
                const formData = new FormData()
                formData.append("file", info.file);
                formData.append("filename", info.file.name)
                // formData.append("file",  file);
                // formData.append("object_dir", this.object_dir)
                const { data: res } = await this.$http.post("upload",
                    formData
                    // {
                    //     formData: formData,
                    , { headers: { "Content-Type": 'multiplecture/form-data; ' } }
                    // }
                )
                if (res.status != 200) return this.$message.error(res.message);
                // this.Catlist =res.data
            },

            lectureOk(id) {
                this.$refs.lectureInfoRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数验证未通过，请按要求录入JZZ内容')
                        if (id == 0) {
                            const { data: res } = await this.$http.post("addLecture", this.lectureInfo)
                            if (res.status != 200) return this.$message.error(res.message);
                            this.$message.success("添加JZZ成功")
                        } else {
                            const { data: res } = await this.$http.put(`editLecture/${id}`, this.lectureInfo)
                            // if(res.status != 200) 
                            if (res.status != 200) return this.$message.error(res.message)
                            this.editLectureVisible = false
                            this.$message.success('更新文章信息成功')
                            this.$refs.lectureInfoRef.resetFields()
                            this.getlectureInfo(this.id)
                            //                     this.editLectureVisible = false
                            // this.$message.success('更新文章信息成功')
                            // this.$refs.editLectureRef.resetFields()
                            // this.getLectureList()
                            // {return this.$message.error(res.message);}
                            // else{
                            // this.getlectureInfo(this.id)
                            // this.$message.success("更新JZZ成功")
                            // }

                        }
                    }

                )
            },

            addCancel() {
                this.$refs.lectureInfoRef.resetFields()
            }
        }
    }



</script>

<style scoped>
    .title {
        text-align: center;

    }

    .el-form-item {
        margin-bottom: 3px;

    }
</style>