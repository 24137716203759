<template>
    <div>
        <el-container>
            <el-header>
                <Header></Header>
            </el-header>

            <el-container >
              <el-aside width="auto">
                <Nav></Nav>
              </el-aside>
              <el-main>
              <router-view ></router-view>
              </el-main>
            </el-container>

        </el-container>
    </div>
</template>

<script>
    import Nav from '../components/layout/Nav'
    import Header from '../components/layout/Header'
    export default {
    components: { Nav,Header }
  }
</script>

<style>
    /* el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  } */

   /* 这个才是控制左侧栏和中间高度的*/
/* .el-aside,.el-main{
  line-height: 100%;  
}
 */


.el-header{
  /* height: 100%; */
  height: 70px;
  background-color: #87ceeb
}


.el-side{
    min-height: 100%;
    height: calc(100vh - 70px);
    background-color: #87ceeb
}

.el-main {
    height: calc(100vh - 70px);
}

</style>