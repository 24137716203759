<template>
    <div class="container">
        <div class="loginBox">
           <el-form ref="loginFormRef" :rules="rules" :model="formdata" class="loginForm">
            <el-form-item prop="username">
                <el-input v-model="formdata.username" placeholder="请输入用户名">
                    <el-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                </el-input>
                <!-- <span class="svg-container">
                    <svg-icon icon-class="user" />
                  </span>
                  <el-input ref="username" v-model="formdata.username" placeholder="请输入用户名" name="username" type="text" tabindex="1" auto-complete="on" />
             -->
                </el-form-item>

            <el-form-item prop="password">
                <el-input v-model="formdata.password" placeholder="请输入密码" type ="password" v-on:keyup.enter="login">
                    <el-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
                </el-input>
            </el-form-item>

            <el-form-item class="loginBtn">
                <el-button type="primary" style="margin: 10px;" @click="login">登录</el-button>
                <el-button type="info" style="margin: 10px;" @click="restForm">取消</el-button>
            </el-form-item>
           </el-form>

           <!-- <el-form :model="CatInfo" :rules="CatRules" ref="editCatRef">
            <el-form-item label="分类名称" prop ="CatInfo">
                <el-input v-model="CatInfo.name" ></el-input>
            </el-form-item>
        </el-form> -->

    </div>
        


    
    </div>
</template>


<script>
    export default{
        data() {
            return {
                formdata:{
                    username:'',
                    password:'',
                },
                rules: {
                    username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    {
                        min: 4,
                        max: 12,
                        message: '用户名必须在4到12个字符之间',
                        trigger: 'blur',
                    },
                    ],
                    password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 20,
                        message: '密码必须在6到20个字符之间',
                        trigger: 'blur',
                    },
                    ],
                },
            }
        },
        methods: {
            restForm(){
                this.$refs.loginFormRef.resetFields()
            },
            login(){
                this.$refs.loginFormRef.validate(async valid=>{
                    if(!valid) return  this.$message.error("登录报错，请检查！")
                    const {data :res} =  await this.$http.post('login',this.formdata)
                    if(res.status != 200){
                        return this.$message.error(res.message)
                    }
                    window.sessionStorage.setItem('token',res.token)
                    this.$router.push('admin')

                })
            }
        },
    }
</script>




<!-- scoped 表示对本页面有用，不会污染全局 -->
<style scoped> 
.container{
   height: 100%;
   background-color:#282c34; 
   /* display: flex;
   justify-content: center;
   align-items: center; */
}
.loginBox{
    width: 450px;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 9px;
}
.loginForm{
    width: 100%;
    position: absolute;
    bottom: 10%;
    padding: 0 20px;
    box-sizing: border-box;
}
.loginBtn{
    display: flex;
    justify-content: flex-end;

}

</style>