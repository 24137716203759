<template>
    <div  >
       
            <el-menu class="el-menu"
            mode="horizontal"  
            background-color="#87ceeb" 
            text-color="#ffffff" 
            active-text-color="#251706">
                <el-menu-item>
                    JZ管理
                </el-menu-item>
                <el-menu-item style="position: absolute;
                bottom: 0;
                right: 10px;">
                    <el-button type ="danger" @click="logout">退出</el-button>
                </el-menu-item >
            </el-menu>

        
    </div>
</template>

<script>
    export default{
        data(){
            return {
                collapsed:false
            }
        },
        methods: {
        logout(){
            window.sessionStorage.clear("token")
            this.$router.push("/login")
        }
    },
    }
</script>

<!-- <style scoped>
    .logo{
        height: 32px;  
        margin: 16px;
        /* background-color: #fff; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
    }
</style> -->