import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Admin from "../views/Admin.vue";
import AddLecture from "../components/lecture/AddLecture.vue";
import LectureList from "../components/lecture/LectureList.vue";
import SendPoints from "../components/points/SendPoints.vue";

// import  ArticleList  from '../components/article/ArticleList.vue'
import CategoryList from "../components/category/CategoryList.vue";
// import  AddUser  from '../components/user/AddUser.vue'
// import  UserList  from '../components/user/UserList.vue'

Vue.use(VueRouter);

const routes = [
  //  {
  //   path:'/',
  //   name:'index',
  //   component: Admin
  //  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "/AddLecture",
        component: AddLecture,
        meta: {
          title: "新增jz",
        },
      },
      {
        path: "/LectureList",
        component: LectureList,
        meta: {
          title: "文章列表",
        },
      },
      {
        path: "/Category",
        component: CategoryList,
        meta: {
          title: "分类",
        },
      },
      {
        path: "/Points",
        component: SendPoints,
        meta: {
          title: "积分",
        },
      },
      {
        path: "/AddLecture/:id",
        component: AddLecture,
        meta: {
          title: "编辑讲座",
        },
        props: true,
      },
    ],
  },

  // {
  //     path:'/admin',
  //     name:'admin',
  //     component: Admin,
  //     children: [
  //       {
  //         path: '/addarticle',
  //         component: AddArticle,
  //         meta: {
  //           title: '新增文章'
  //         }
  //       },
  //       {
  //         path: '/addarticle/:id',
  //         component: AddArticle,
  //         meta: {
  //           title: '编辑文章'
  //         },
  //         props:true
  //       },
  //       {
  //         path: '/getarticlelist',
  //         component: ArticleList,
  //         meta: {
  //           title: '文章列表'
  //         }
  //       },
  //       {
  //         path: '/getcategory',
  //         component: CategoryList,
  //         meta: {
  //           title: '文章列表'
  //         }
  //       },
  //       {
  //         path: '/getuser',
  //         component: UserList,
  //         meta: {
  //           title: '文章列表'
  //         }
  //       },
  //     ]
  // }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem("token");
  if (to.path == "/login") return next();
  if (!token) {
    next("/login");
  } else {
    next();
  }
});

export default router;
