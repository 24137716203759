<template>
  <el-menu
    background-color="#e5e6e6"
    text-color="#99c5d3"
    active-text-color="#251706"
    router
  >
    <!-- <el-menu-item>
            JZ管理
        </el-menu-item> -->
    <el-submenu index="1">
      <template slot="title">
        <!-- <i class="el-icon-location"></i> -->
        <span>JZ管理</span>
      </template>
      <el-menu-item key="AddLecture" index="/AddLecture"> 增加JZ </el-menu-item>
      <el-menu-item key="LectureList" index="/LectureList">
        JZ列表
      </el-menu-item>
    </el-submenu>

    <!-- <el-menu-item key="AddLecture" index="AddLecture">
            增加JZ
        </el-menu-item> -->
    <el-menu-item key="Category" index="/Category"> 分类列表 </el-menu-item>
    <el-menu-item key="Points" index="/Points"> 积分管理 </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style scoped>
/* .logo{
        height: 32px;  
        margin: 16px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
    } */
.el-menu {
  /* height:100% */
  height: calc(100vh - 70px);
}
</style>
