<template>
    <div>
        <h3 class="title">jz列表</h3>

        <el-card>
            <el-row :gutter="20">

                <!-- <el-col :span="6">
                    <el-input-search v-model="queryParam.title" 
                    placeholder="输入文章名查找" 
                    enter-button 
                    allowClear
                    @search="searchLecture"></el-input-search>
                </el-col> -->

                <el-col :span="6">
                    <el-input type="text" prefix-icon="el-icon-search" v-model="queryParam.title" placeholder="输入文章名查找"
                        style="width: 270px; cursor: pointer" @keyup.enter.native="searchLecture"></el-input>
                </el-col>

                <el-col :span="4">
                    <el-button type="primary" @click="$router.push(`/AddLecture`)">新增</el-button>
                </el-col>


                <el-col :span="6" :offset="4">
                    <el-select v-model="lectureInfo.cid" placeholder="请选择分类" style="width: 200px;" @change="CatChange">
                        <el-option v-for="item in Catlist" :key="item.ID" :value="item.ID" :label="item.name">
                            {{item.name}}
                        </el-option>
                    </el-select>

                </el-col>
            </el-row>

            <!--  讲座列表区域 -->
            <el-table :data="Lecturelist" border style="width: 100%">
                <template v-for="(item,index) in columns">
                    <el-table-column :key="index" :prop="item.dataIndex" :label="item.title" width="150">
                    </el-table-column>
                </template>

                <el-table-column label="分类">
                    <template slot-scope="scope2">
                        <span>{{scope2.row.Category.name}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!-- <el-button type="success" @click="editPush(scope.row.ID,scope.index)">编辑 <i class="el-icon-edit"></i></el-button> -->
                        <el-button type="success" @click="editPush(scope.row.ID)">编辑 <i
                                class="el-icon-edit"></i></el-button>

                        <el-button type="danger">删除 <i class="el-icon-remove-outline"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="queryParam.pageNum" :page-sizes="[2, 5, 10, 30, 50]" :page-size="queryParam.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="queryParam.total">
                </el-pagination>
            </div>
        </el-card>


    </div>
</template>

<script>

    const columns = [
        {
            title: "ID",
            dataIndex: "ID",
            width: "10%",
            key: 'id',
            align: "center"
        },
        {
            title: "JJ标题",
            dataIndex: "name",
            width: "20%",
            key: 'name',
            align: "center"
        }
    ]
    export default {
        props: ['id'],
        data() {
            return {
                pagination: {
                    pageSizeOptions: ['2', '5', '10'],
                    pageSize: 5,
                    total: 0,
                    showSizeChanger: true,
                    showTotal: (total) => `共${total}条`,
                },
                Lecturelist: [],
                Catlist: [],
                Catlists: [],
                columns,
                queryParam: {
                    title: "",
                    pageSize: 10,
                    pageNum: 1,
                    total: 0,
                },
                lectureInfo: {
                    id: 0,
                    name: '',
                    cid: undefined,
                    begin_time: undefined,
                    end_time: undefined,
                    on_line: false,
                    on_line_type: undefined,
                    on_line_addr: '',
                    on_line_pw: '',
                    unit: '',
                    compus: '',
                    addr: '',
                    speaker: '',
                    speaker_profile: '',
                    host: '',
                    host_profile: '',
                    content: '',
                    image: '',
                },
                visible: false,
                addLectureVisible: false,
                editLectureVisible: false,
                LectureRules: {
                    title: [
                        {
                            required: true, message: "请输入文章标题", trigger: "blur"
                        },
                        {
                            min: 1, max: 12, message: "文章标题应该在1到12位之间", trigger: "blur"
                        }
                    ]
                }
            }
        },



        created() {
            this.getLectureList()
            this.getCatList()

        },
        mounted() {
            if (this.id) {
                this.getLectureInfo(this.id)
            }
        },
        methods: {

            editPush(id) {
                this.$router.push({ path: `/AddLecture/${id}` })
            },

            async getLectureInfo(id) {
                const { data: res } = await this.$http.post("getLecture", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.lectureInfo = res.data
                this.lectureInfo.id = res.data.ID
            },
            
            handleSizeChange(val) { // 修改每页所存数据量的值所触发的函数
                this.queryParam.pageSize = val;  // 修改页的大小
                this.getLectureList();       // 按新的pageNo和pageSize进行查询
            },

            handleCurrentChange(val) { // 修改当前页所触发的函数
                this.queryParam.pageNum = val;       // 更新当前的页
                this.getLectureList();          // 按新的pageNo和pageSize进行查询
            },

            async editLecture(id) {
                this.editLectureVisible = true
                const { data: res } = await this.$http.post(`getLecture`,
                    { "id": id })
                this.LectureInfo = res.data
                this.Lecture.id = id
            },


            async getCatList() {
                const { data: res } = await this.$http.post("getCategoryAll")
                if (res.status != 200) return this.$message.error(res.message);
                this.Catlist = res.data;
                this.Catlists = res.data
            },

            CatChange(cid) {
                console.log("idddd")
                console.log(cid)
                this.getLectureListByCat(cid)
            },

            async getLectureListByCat(id) {
                const { data: res } = await this.$http.post("getLectureByCategory", {
                    id: id,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Lecturelist = res.data
                this.queryParam.total = res.total
            },

            async getLectureList() {
                const { data: res } = await this.$http.post("getLectureList", {
                    title: this.queryParam.title,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Lecturelist = res.data
                this.queryParam.total = res.total
            },

            async searchLecture() {
                this.queryParam.pageNum = 1
                this.queryParam.current = 1
                const { data: res } = await this.$http.post("getLectureList", {
                    title: this.queryParam.title,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Lecturelist = res.data
                this.queryParam.total = res.total
            },

            deleteLecture(id) {
                this.$confirm({
                    title: '提示：请再次确认',
                    content: '确定要删除该文章吗？一旦删除，无法恢复',
                    onOk: async () => {
                        const { data: res } = await this.$http.delete(`delLecture/${id}`)
                        if (res.status != 200) return this.$message.error(res.message)
                        this.$message.success('删除成功')
                        this.getLectureList()
                    },
                    onCancel: () => {
                        this.$message.info('已取消删除')
                    },
                })
            },

            addLectureOk() {
                this.$refs.addLectureRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数不符合要求，请重新输入')
                        const { data: res } = await this.$http.post(`addLecture`,
                            {
                                title: this.lectureInfo.title,
                                password: this.lectureInfo.password,
                                role: this.lectureInfo.role
                            })
                        if (res.status != 200) return this.$message.error(res.message)
                        this.$message.success('添加文章成功')
                        this.addLectureVisible = false
                        this.$refs.addLectureRef.resetFields()
                        this.getLectureList()
                    })

            },

            addLectureCancel() {
                this.$refs.addLectureRef.resetFields()
                this.addLectureVisible = false
            },

            adminChange(value) {
                // console.log(value)
                this.lectureInfo.role = value
                // this.lectureInfo.role = parseInt(value)
            },

            async editLecture(id) {
                this.editLectureVisible = true
                const { data: res } = await this.$http.post(`getLecture`,
                    { "id": id })
                this.lectureInfo = res.data
                this.lectureInfo.id = id
            },

            editLectureOk() {
                this.$refs.editLectureRef.validate(async (valid) => {
                    if (!valid) return this.$message.error('参数不符合要求，请重新输入')
                    const { data: res } = await this.$http.put(`editLecture/${this.lectureInfo.id}`,
                        this.lectureInfo
                    )
                    c
                })
            },

            editLectureCancel() {
                this.$refs.editLectureRef.resetFields()
                this.editLectureVisible = false
                this.$message.info("编辑取消")
            },

            handleTableChange(pagination, filters, sorter) {
                var pager = { ...this.pagination }
                pager.current = pagination.current
                pager.pageSize = pagination.pageSize
                this.queryParam.pageSize = pagination.pageSize
                this.queryParam.pageNum = pagination.current

                if (pagination.pageSize !== this.pagination.pageSize) {
                    this.queryParam.pageNum = 1
                    pager.current = 1
                }

                this.pagination = pager
                this.getLectureList()
            },

        },

    }
</script>

<style scoped>
    .actionSlot {
        display: flex;
        justify-content: center;
    }

    .title {
        text-align: center;
    }
</style>